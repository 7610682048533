.header {
  background-color: #fff;
  position: relative;
  top: 0;
}

.navbar-menu.is-active {
  display: block;
}

/*# sourceMappingURL=index.e969fd86.css.map */
